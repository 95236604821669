import React, { createContext, useState } from 'react';

export const WalletContext = createContext();

// This context provider is passed to any component requiring the context
export const WalletProvider = ({ children }) => {
  const [address, setAddress] = useState('');
  const [provider, setProvider] = useState('');

  return (
    <WalletContext.Provider
      value={{
        provider,
        setProvider,
        address,
        setAddress,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
