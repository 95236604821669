import React from 'react';
import './Landingpage.css';

const Faqpage = () => {
  return (
    <section className="page-section" style={{ marginTop: '60px' }}>
      <h2 className="section_heading">FAQ</h2>
      <div id="faq_section" className="row">
        <details className="col-md-7 col-10">
          <summary>How much does it cost to mint a Hung?</summary>
          <p>It only costs 0.069 ETH to mint one Hung</p>
        </details>
        <details className="col-md-7 col-10">
          <summary>How many Hungs can I mint during the initial sale? </summary>
          <p>
            A maximum of 20 Hungs can be minted per transaction. You can,
            however, execute as many transactions as you would like if you want
            to enter the upper echelon of the Hung Gang. Top holders will
            receive additional utility soon after launch
          </p>
        </details>
        <details className="col-md-7 col-10">
          <summary>When did Crypto Hungs launch?</summary>
          <p>The minting contract was deployed on September 17, 2021</p>
        </details>
        <details className="col-md-7 col-10">
          <summary>When will Hungs be revealed?</summary>
          <p> Hungs will be revealed upon minting.</p>
        </details>
        <details className="col-md-7 col-10">
          <summary>
            What if I have a question that hasn’t been answered here?
          </summary>
          <p>
            The Crypto Hung team is active in Discord. Hop on over here! (
            <a
              href="https://discord.gg/RWeqAyvR8c"
              target="_blank"
              rel="noreferrer"
            >
              link
            </a>
            )
          </p>
        </details>
      </div>
    </section>
  );
};

export default Faqpage;
