import { useState, useContext, useEffect } from "react";
import { WalletContext } from "./WalletContext";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import "./Landingpage.css";
import { API } from "../../config";
import "./Landingpage.css";

interface IWallet {
  WalletConnected: Boolean;
  address: String | null;
}
const WalletConnect = () => {
  const [account, setAccount] = useState<IWallet>({
    WalletConnected: false,
    address: "",
  });
  const [availableNfts, setAvailable] = useState<Number | null>(null);
  const [totalNfts, setTotal] = useState<Number | null>(null);

  useEffect(() => {
    getNftsCount();
  }, []);

  const getNftsCount = () => {
    fetch(`${API}/api/nfts-count`, {
      method: "GET",
      headers: {
        Accept: "*/*",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setAvailable(response.availableNfts);
        setTotal(response.totalNfts);
      })
      .catch((err) => console.log(err));
  };

  const WalletAddress = useContext(WalletContext);
  const [provider, setProvider] = useState<any | null>();

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: "b6a55130c58d485a9520ca65cc9538eb",
      },
    },
  };

  let web3Modal = new Web3Modal({
    cacheProvider: false,
    providerOptions,
  });

  const connectWallet = async () => {
    try {
      const pr = await web3Modal.connect();
      setProvider(pr);
      const web3 = new Web3(pr);

      const accounts = await web3.eth.getAccounts();
      if (!!accounts && accounts.length > 0) {
        setAccount({
          WalletConnected: true,
          address: accounts[0],
        });

        WalletAddress.setAddress(accounts[0]);
        WalletAddress.setProvider(web3);
      } else {
        setAccount({
          WalletConnected: false,
          address: null,
        });
      }
    } catch (e) {
      console.log("Could not get Link wallet connection", e);
      return;
    }
  };

  const disconnectWallet = async () => {
    if (provider && provider.close) {
      await provider.close();
      await web3Modal.clearCachedProvider();
      setProvider(null);
    }
    setAccount({
      WalletConnected: false,
      address: null,
    });
  };

  return (
    <section id="wallet__connect" className="page-section">
      {!account.WalletConnected ? (
        <button
          className="connect_btn  btn-lg btn-block"
          onClick={connectWallet}
        >
          <i
            style={{
              color: "rgb(245, 101, 101)",
              fontSize: "10px",
              position: "relative",
              top: "-2px",
              marginRight: "5px",
            }}
            className="fas fa-circle"
          ></i>
          Disconnected
        </button>
      ) : (
        <button
          className="connect_btn btn-outline-secondary btn-lg btn-round"
          tabIndex={-1}
          aria-disabled="true"
          onClick={disconnectWallet}
        >
          <i
            style={{
              color: "rgb(0, 221, 11)",
              fontSize: "10px",
              position: "relative",
              top: "-3px",
              marginRight: "5px",
            }}
            className="fas fa-circle"
          ></i>
          Connected
        </button>
      )}

      {availableNfts !== null && totalNfts !== null && (
        <p
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "25px",
            fontWeight: 600,
          }}
        >
          Available: {availableNfts.toLocaleString()}/
          {totalNfts.toLocaleString()}
        </p>
      )}
    </section>
  );
};

export default WalletConnect;
