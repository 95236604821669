import React from "react";
import "./Background.css";
const Background = () => {
  return (
    <div id="background">
      <div className="emoji1">🍆</div>
      <div className="emoji2">🍆</div>
      <div className="emoji3">🍆</div>
      <div className="emoji4">🍆</div>
      <div style={{ left: "1000px", top: "0px" }} className="emoji5">
        🍆
      </div>
      <div style={{ left: "300px", top: "500px" }} className="emoji6">
        🍆
      </div>
      <div style={{ left: "1003px", top: "0px" }} className="emoji7">
        🍆
      </div>
      <div style={{ left: "3px", top: "90px" }} className="emoji6">
        🍆
      </div>
      <div style={{ left: "280px", top: "0px" }} className="emoji7">
        🍆
      </div>
      <div style={{ left: "600px", top: "0px" }} className="emoji5">
        🍆
      </div>
      <div style={{ left: "600px", top: "0px" }} className="emoji10">
        🍆
      </div>
      <div style={{ left: "800px", top: "0px" }} className="emoji10">
        🍆
      </div>
      <div style={{ left: "0px", top: "250px" }} className="emoji10">
        🍆
      </div>
      <div style={{ left: "0px", top: "450px" }} className="emoji8">
        🍆
      </div>
      <div style={{ left: "0px", top: "110px" }} className="emoji9">
        🍆
      </div>
      <div style={{ left: "100px", top: "0px" }} className="emoji8">
        🍆
      </div>
      <div style={{ left: "1890px", top: "0px" }} className="emoji8">
        🍆
      </div>
      <div style={{ left: "809px", top: "0px" }} className="emoji7">
        🍆
      </div>
      <div style={{ left: "380px", top: "0px" }} className="emoji8">
        🍆
      </div>
      <div style={{ left: "901px", top: "0px" }} className="emoji11">
        🍆
      </div>
      <div style={{ left: "170px", top: "0px" }} className="emoji12">
        🍆
      </div>
      <div style={{ left: "568px", top: "0px" }} className="emoji9">
        🍆
      </div>
      <div style={{ left: "568px", top: "0px" }} className="emoji5">
        🍆
      </div>
      <div style={{ left: "0px", top: "0px" }} className="emoji12">
        🍆
      </div>
      <div style={{ left: "568px", top: "0px" }} className="emoji11">
        🍆
      </div>
      <div style={{ left: "668px", top: "0px" }} className="emoji12">
        🍆
      </div>
      <div style={{ left: "1200px", top: "0px" }} className="emoji13">
        🍆
      </div>
      <div style={{ left: "600px", top: "10px" }} className="emoji13">
        🍆
      </div>
      <div style={{ left: "0px", top: "0px" }} className="emoji13">
        🍆
      </div>
      <div style={{ left: "0px", top: "400px" }} className="emoji13">
        🍆
      </div>
      <div style={{ left: "0px", top: "110px" }} className="emoji13">
        🍆
      </div>
    </div>
  );
};

export default Background;
