import React, { useEffect } from 'react';
import './Landingpage.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Footer from './Footer';
import MintSection from './MintSection';
import { WalletProvider } from './WalletContext';
import WalletConnect from './WalletConnect';
import FaqPage from './Faqpage';
import Roadmap from './Roadmap';
import Gallery from './Gallery';
import Banner from './Banner';
// import AboutUs from '../AboutUs/AboutUs';
const LandingPage = () => {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);

  return (
    <>
      <WalletProvider>
        <div className="main__div">
          <div className="walletConnect">
            <WalletConnect />
          </div>
          <Banner />

          <MintSection />
          <FaqPage />
          <Roadmap />
          <Gallery />
          {/* <AboutUs /> */}
          <Footer />
        </div>
      </WalletProvider>
    </>
  );
};

export default LandingPage;
