import React, { useState, useContext } from 'react';
import axios from 'axios';
import { WalletContext } from './WalletContext';
import { API } from '../../config';

// interface Imetadata {
//   id: String;
//   name: String;
//   image: String;
//   by: String;
//   attributes: {
//     head: [
//       {
//         headName: String;
//         headRarity: Number;
//       }
//     ];
//     eyes: [
//       {
//         eyesName: String;
//         eyesRarity: Number;
//       }
//     ];
//     earring: [
//       {
//         earring: String;
//         eyesRarity: String;
//       }
//     ];
//     necklace: [
//       {
//         necklaceName: String;
//         necklaceRarity: Number;
//       }
//     ];
//     body: [
//       {
//         bodyName: String;
//         bodyRarity: Number;
//       }
//     ];
//     face: [
//       {
//         faceName: String;
//         faceRarity: Number;
//       }
//     ];
//   };
// }

// interface IResponse {
//   _id: String;
//   pinataHash: String;
//   head: String;
//   headRarity: Number;
//   eyes: String;
//   eyesRarity: Number;
//   earring: String;
//   earringRarity: Number;
//   necklace: String;
//   necklaceRarity: Number;
//   body: String;
//   bodyRarity: Number;
//   face: String;
//   faceRarity: Number;
// }
const MintSection = () => {
  const [nft, setNft] = useState<String[] | null>(null);
  const [nftCount, setNftCount] = useState<Number | String>(1);

  const [minting, setMinting] = useState(false);
  const [error, setError] = useState<String | null>(null);
  const WalletAddress = useContext(WalletContext);

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    console.log(WalletAddress);
    if (WalletAddress.address === '') alert('Please connect your wallet first');
    else {
      setMinting(true);
      const abi = [
        {
          inputs: [
            {
              internalType: 'string',
              name: 'NAME',
              type: 'string',
            },
            {
              internalType: 'string',
              name: 'SYMBOL',
              type: 'string',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'approved',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'Approval',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'operator',
              type: 'address',
            },
            {
              indexed: false,
              internalType: 'bool',
              name: 'approved',
              type: 'bool',
            },
          ],
          name: 'ApprovalForAll',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: 'uint256',
              name: '_totalNft',
              type: 'uint256',
            },
            {
              indexed: false,
              internalType: 'string',
              name: 'msg',
              type: 'string',
            },
          ],
          name: 'BatchMint',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'previousOwner',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'OwnershipTransferred',
          type: 'event',
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'Transfer',
          type: 'event',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'approve',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
          ],
          name: 'balanceOf',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: '_totalNft',
              type: 'uint256',
            },
            {
              internalType: 'string[]',
              name: '_uri',
              type: 'string[]',
            },
          ],
          name: 'batchMint',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'payable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'getApproved',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'operator',
              type: 'address',
            },
          ],
          name: 'isApprovedForAll',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'name',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'owner',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'ownerOf',
          outputs: [
            {
              internalType: 'address',
              name: '',
              type: 'address',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'renounceOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'safeTransferFrom',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
            {
              internalType: 'bytes',
              name: '_data',
              type: 'bytes',
            },
          ],
          name: 'safeTransferFrom',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'operator',
              type: 'address',
            },
            {
              internalType: 'bool',
              name: 'approved',
              type: 'bool',
            },
          ],
          name: 'setApprovalForAll',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'bytes4',
              name: 'interfaceId',
              type: 'bytes4',
            },
          ],
          name: 'supportsInterface',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'symbol',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'tokenIds',
          outputs: [
            {
              internalType: 'uint256',
              name: '_value',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'tokenURI',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'transferFrom',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'newOwner',
              type: 'address',
            },
          ],
          name: 'transferOwnership',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ];

      const web3 = WalletAddress.provider;
      const NFTproject = new web3.eth.Contract(
        abi,
        process.env.REACT_APP_SMART_CONTRACT_ADDRESS
      );

      try {
        const randomNftResponse = await axios.get(
          `${API}/api/random-nft?limit=${nftCount}`
        );

        console.log({ randomNftResponse });

        if (randomNftResponse.data) {
          const metadata: any[] = [];
          randomNftResponse.data.forEach((element: any) => {
            metadata.push({
              id: element._id,
              name: `Hung ${element.name}`,
              image: `ipfs://${element.pinataHash}`,
              by: WalletAddress.address,
              attributes: {
                head: element.head,
                eyes: element.eyes,
                earring: element.earring,
                necklace: element.necklace,
                body: element.body,
                face: element.face,
              },
            });
          });

          const pinataResponse = await Promise.all(
            metadata.map(async (element) => {
              let hashElement = await axios.post(
                'https://api.pinata.cloud/pinning/pinJSONToIPFS',
                element,
                {
                  headers: {
                    pinata_api_key: process.env.REACT_APP_PINATA_API_KEY,
                    pinata_secret_api_key:
                      process.env.REACT_APP_PINATA_API_SECRET_KEY,
                  },
                }
              );
              return hashElement.data;
            })
          );
          console.log(pinataResponse);
          const IpfsArray = pinataResponse.map((element) => {
            return element.IpfsHash;
          });

          await NFTproject.methods.batchMint(nftCount, IpfsArray).send({
            from: WalletAddress.address,
            value: 0.069 * metadata.length * 10 ** 18,
          });

          const params = metadata.map((element) => {
            return element.id;
          });
          await axios.post(`${API}/api/mint`, params);

          setNft(
            randomNftResponse.data.map((element: any) => {
              return element.pinataHash;
            })
          );
        } else {
          setError(`No NFT's are available right now.`);
        }
      } catch (err: any) {
        if (err?.response?.status === 404) {
          setError('Required Number of NFTs not available!');
        } else {
          setError('NFTs could not be minted...');
        }
      }
      setMinting(false);
    }
  };

  return (
    <section
      id="mint_section"
      className="page-section"
      style={{ marginTop: '-60px' }}
    >
      <div
        className="container-fluid form-div"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h2 className="section_heading">Join the Gang</h2>
        <input
          className="mt-3 rounded"
          type="number"
          min="1"
          max="20"
          value={nftCount.toString()}
          onChange={(e) => {
            setNftCount(e.target.value);
          }}
        ></input>
        {!nft ? (
          <button
            className="btn btn-primary mint_btn"
            onClick={handleSubmit}
            style={{
              height: '50px',
              width: '200px',
              fontSize: '22px',
              fontWeight: 1000,

              textDecoration: 'bold',
            }}
            disabled={minting}
          >
            {minting ? 'MINTING...' : 'GET HUNG'}
          </button>
        ) : (
          <>
            <p
              style={{ color: 'green', margin: '10px 0', fontWeight: 800 }}
              className="alert alert-success"
            >
              Congratulations! YOU ARE HUNG!
            </p>
            <div className="row">
              {nft.map((element) => {
                return (
                  <img
                    className="col-2 my-3"
                    src={`https://gateway.pinata.cloud/ipfs/${element}`}
                    style={{ width: '200px', height: '200px' }}
                    alt="NFT"
                  />
                );
              })}
            </div>
            <button
              className="btn btn-secondary"
              style={{ color: 'white', marginTop: '20px' }}
              onClick={() => {
                setNft(null);
                setNftCount(1);
              }}
            >
              Okay
            </button>
          </>
        )}
        {error && (
          <>
            <p
              style={{ marginTop: '20px', fontWeight: 800 }}
              className="alert alert-danger"
            >
              {error}
            </p>
            <button
              className="btn btn-secondary"
              style={{ color: 'white', marginTop: '20px' }}
              onClick={() => {
                setError(null);
              }}
            >
              Okay
            </button>
          </>
        )}
      </div>
    </section>
  );
};

export default MintSection;
