import './Landingpage.css';
import ImageCard from './ImageCard';
import img1 from '../images/11.png';
import img2 from '../images/14.png';
import img3 from '../images/26.png';
import img4 from '../images/29.png';
import img5 from '../images/34.png';
import img6 from '../images/35.png';
import img7 from '../images/38.png';
import img8 from '../images/39.png';
import img9 from '../images/55.png';
import img10 from '../images/96.png';
import img11 from '../images/132.png';
import img12 from '../images/181.png';

const Gallery = () => {
  return (
    <section id="gallery" className="page-section">
      <div id="gallery-container" className="container-fluid">
        <ImageCard image={img1} />
        <ImageCard image={img2} />
        <ImageCard image={img3} />
        <ImageCard image={img4} />
        <ImageCard image={img5} />
        <ImageCard image={img6} />
        <ImageCard image={img7} />
        <ImageCard image={img8} />
        <ImageCard image={img9} />
        <ImageCard image={img10} />
        <ImageCard image={img11} />
        <ImageCard image={img12} />
      </div>
    </section>
  );
};

export default Gallery;
