import React from 'react';
import './Landingpage.css';
import twitter from '../images/twitter.svg';
import discord from '../images/discord.svg';

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer-wrapper">
          <p>Copyright &copy; 2021. All rights reserved.</p>
          <ul className="social">
            <li>
              <a
                href="https://twitter.com/CryptoHungs"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitter} className="img-fluid" alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://discord.gg/RWeqAyvR8c"
                target="_blank"
                rel="noreferrer"
              >
                <img src={discord} className="img-fluid" alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
