import { Link } from 'react-router-dom';
import discord from '../images/discord.svg';
import twiter from '../images/twitter.svg';
import opensea from '../images/opensea.png';
import './NavBar.css';

const NavBar = () => {
  return (
    <header>
      <nav className="navbar navbar-expand-md">
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <i className="fas fa-bars"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              {/* <li
                className="nav-item"
                onClick={() => window.scrollBy({ top: 0, behavior: 'smooth' })}
              >
                <Link className="nav-link" aria-current="page" to="#aboutus">
                  About Us
                </Link>
              </li> */}
              <li
                className="nav-item"
                onClick={() =>
                  window.scrollBy({ top: 1500, behavior: 'smooth' })
                }
              >
                <Link className="nav-link" aria-disabled="true" to="#roadmap">
                  Roadmap
                </Link>
              </li>
              <li
                className="nav-item"
                onClick={() =>
                  window.scrollBy({ top: 2000, behavior: 'smooth' })
                }
              >
                <Link className="nav-link" aria-disabled="true" to="#gallery">
                  Gallery
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-disabled="true"
                  href="/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    window.open('https://opensea.io/collection/cryptohungs')
                  }
                >
                  <img src={opensea} alt="opensea" className="image-icon" />
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-disabled="true"
                  href="https://discord.gg/RWeqAyvR8c"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={discord} alt="discord" className="image-icon" />
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-disabled="true"
                  href="https://twitter.com/CryptoHungs"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twiter} alt="twiter" className="image-icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;
