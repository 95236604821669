import "./Landingpage.css";

const Roadmap = () => {
  return (
    <section id="roadmap" className="page-section">
      <h2 className="section_heading">Roadmap</h2>
      <div className="row">
        <div className="col-md-7 col-10">
          <h3>Airdrops</h3>
          <p>
            Once all Hungs are minted, a snapshot will be taken and a special
            key to the Hung citadel will be dropped to the whole Hung Gang. A
            more rare airdrop will also be sent to the largest holders (&gt;20
            Hungs)
          </p>
        </div>
        <div className="col-md-7 col-10">
          <h3>Charity</h3>
          <p>$69,000 donation to a charity voted on by the community.</p>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
