import { HashRouter as Router, Switch, Route } from 'react-router-dom';
// import AddNFT from './components/AddNFT/AddNFT';
import Background from './components/Background/Background';
import LandingPage from './components/LandingPage/LandingPage';
import NavBar from './components/LandingPage/Navbar';

function App() {
  return (
    <div className="App">
      <Router>
        <Background />
        <NavBar />
        <Switch>
          <Route path="/" exact component={LandingPage} />
          {/* <Route path="/add-nft" exact component={AddNFT} /> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
