import './Landingpage.css';
import img1 from '../images/11.png';
import img2 from '../images/14.png';
import img3 from '../images/26.png';
import img4 from '../images/29.png';
import img5 from '../images/34.png';
import img6 from '../images/35.png';
import img7 from '../images/38.png';
import img8 from '../images/39.png';
import img9 from '../images/55.png';
import img10 from '../images/96.png';
import img11 from '../images/132.png';
import img12 from '../images/181.png';
import img13 from '../images/296.png';
import img14 from '../images/307.png';
import img15 from '../images/311.png';
import img16 from '../images/323.png';
import img17 from '../images/332.png';

const Banner = () => {
  return (
    <div id="banner_div">
      <h1 id="brand-name">CRYPTO HUNGS</h1>
      <div className="row mb-5">
        <div
          className="col-md-9  col-12 title-section"
          style={{ marginBottom: '40px' }}
        >
          <div id="title-text">
            <p>
              CryptoHungs are a collection of 6,969 randomly generated Crypto
              Hung NFTs on the Ethereum blockchain and parody of the OG Crypto
              Punks. The 6969 Hungs create our community known as The Hung Gang.
              Mint yourself a Hung and join the Hung Gang to pay homage to and
              immortalize the Hung God - Barry Wood.
            </p>
          </div>
        </div>
        <div
          className="col-xl-4 col-lg-5 col-md-6 col-sm-10 col-xs-12"
          data-aos="fade-left"
        >
          <img className="container banner-image" src={img1} alt="Banner" />
          <img className="container banner-image" src={img2} alt="Banner" />
          <img className="container banner-image" src={img3} alt="Banner" />
          <img className="container banner-image" src={img4} alt="Banner" />
          <img className="container banner-image" src={img5} alt="Banner" />
          <img className="container banner-image" src={img6} alt="Banner" />
          <img className="container banner-image" src={img7} alt="Banner" />
          <img className="container banner-image" src={img8} alt="Banner" />
          <img className="container banner-image" src={img9} alt="Banner" />
          <img className="container banner-image" src={img10} alt="Banner" />
          <img className="container banner-image" src={img11} alt="Banner" />
          <img className="container banner-image" src={img12} alt="Banner" />
          <img className="container banner-image" src={img13} alt="Banner" />
          <img className="container banner-image" src={img14} alt="Banner" />
          <img className="container banner-image" src={img15} alt="Banner" />
          <img className="container banner-image" src={img16} alt="Banner" />
          <img className="container banner-image" src={img17} alt="Banner" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
